/* eslint-disable @calm/react-intl/missing-formatted-message */
import { Box } from "@kaizen/component-library"
import { Heading } from "@kaizen/typography"
import { assetUrl } from "@kaizen/hosted-assets"
import React from "react"
import classNames from "classnames"

import styles from "./Head.module.scss"

interface Props {
  central?: boolean
}

export const Head: React.FC<Props> = ({ central = false }) => {
  return (
    <Box
      classNameOverride={classNames(styles.head, central ? styles.central : {})}
    >
      <title>Sign in to Culture Amp</title>
      <Box classNameOverride={styles.logo}>
        <a href="https://www.cultureamp.com/" aria-label="Culture Amp home">
          <img
            src={assetUrl("brand/logo-horizontal-default.svg")}
            alt="Culture Amp"
          />
        </a>
      </Box>
      <Box classNameOverride={styles.welcome}>
        <Heading variant="display-0" tag="h1">
          Let&rsquo;s create a better
          <br />
          world of work
        </Heading>
      </Box>
    </Box>
  )
}
