import * as JWT from "jose"
import { Checks } from "../types/PasswordReset/password-reset-form"
import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from "@aws-sdk/client-secrets-manager"
import { NextApiRequest } from "next"
import { Token, TokenValue } from "../types"
import { determineEnvironment } from "@cultureamp/frontend-env"
import { getCookie } from "cookies-next"
import React from "react"

const redirectCookieGetter = (req: NextApiRequest): string => {
  // Access the cookies from the request object
  const cookie = getCookie("redirect_to", { req }) as string

  return cookie
}

export interface Secret {
  apiKey: string
  faUrl: string
  hostUrl: string
  tenantId: string
  cryptoSecret: string
  oAuth: OauthOptions
  basicAuthForResetPassword: BasicAuthForResetPassword
}

export interface OauthOptions {
  google: GoogleOauthOption
}

interface GoogleOauthOption {
  clientId: string
  clientSecret: string
}
interface BasicAuthForResetPassword {
  username: string
  password: string
}

let cachedSecret: Record<string, Secret> = {}

/**
 * Clears the cached key
 */
export function clearCachedSecret() {
  cachedSecret = {}
}

/**
 * Reads FA API Key from AWS secrets manager
 *
 * @returns The secret value
 */
export async function getFusionAuthSecret(
  req: NextApiRequest,
): Promise<Secret> {
  if (process.env.NODE_ENV === "development") {
    return {
      apiKey: process.env.FUSIONAUTH_APP_API_KEY ?? "",
      faUrl: process.env.FUSIONAUTH_SERVER_URL ?? "",
      hostUrl: process.env.FUSIONAUTH_APP_REDIRECT_HOST ?? "",
      tenantId: process.env.FUSIONAUTH_DEFAULT_TENANT_ID ?? "",
      cryptoSecret: process.env.APP_CRYPTO_SECRET ?? "",
      oAuth: {
        google: {
          clientId: process.env.GOOGLE_OAUTH_CLIENT_ID ?? "",
          clientSecret: process.env.GOOGLE_OAUTH_CLIENT_SECRET ?? "",
        },
      },
      basicAuthForResetPassword: {
        username: process.env.BASIC_AUTH_FOR_RESET_PASSWORD_USERNAME ?? "",
        password: process.env.BASIC_AUTH_FOR_RESET_PASSWORD_PASSWORD ?? "",
      },
    }
  }

  const farm = getFarmName(req)

  if (!cachedSecret[farm]) {
    try {
      const smClient = new SecretsManagerClient({})
      const result = await smClient.send(
        new GetSecretValueCommand({
          SecretId: `/authentication-ui/consumed-from/fusionauth-ops/${farm}`,
        }),
      )
      const {
        authUiApiKey,
        faUrl,
        hostUrl,
        tenantId,
        cryptoSecret,
        oAuth,
        basicAuthForResetPassword,
      } = JSON.parse(result.SecretString!)

      cachedSecret[farm] = {
        apiKey: authUiApiKey,
        faUrl: removeTrailingSlash(faUrl),
        hostUrl,
        tenantId,
        cryptoSecret,
        oAuth,
        basicAuthForResetPassword,
      }
    } catch (e) {
      throw new Error(`Unable to read FusionAuth API key: ${e}`)
    }
  }

  return cachedSecret[farm] as Secret
}

/**
 * Retrieves the farm name from `x-ca-farm` request header
 * @see https://github.com/cultureamp/web-gateway/blob/9908ead5c6ffa72e8e7a340008a213036f045b17/route/router.go#L151-L152
 * @see https://github.com/cultureamp/web-gateway/blob/9908ead5c6ffa72e8e7a340008a213036f045b17/forward/frontend/frontend_app.go#L53-L55
 *
 * @param req The incoming message
 * @returns A farm name or throws an error otherwise
 */
export function getFarmName(req?: NextApiRequest): string {
  const header = req?.headers["x-ca-farm"]
  if (!header) {
    throw new Error(
      "Unable to read request header `x-ca-farm` from incoming message",
    )
  }

  return Array.isArray(header) ? header[0]! : header
}

/**
 * Determinse the environment and retrieve environment name for Sentry
 *
 * @returns The environment name
 */
export function getEnvironmentName() {
  const environment = determineEnvironment()

  return environment.kind == "development" ? environment.name : environment.kind
}

/**
 * Determinse the error is instance of Error and get error message
 *
 * @returns The error message
 */
export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message
  return JSON.stringify(error)
}

type EmptyObject<T> = { [K in keyof T]?: never }
type EmptyObjectOf<T> = EmptyObject<T> extends T ? EmptyObject<T> : never

/**
 * Determinse if an optional object is empty
 *
 * @returns if an optional object is empty
 */
export const isObjEmpty = <T extends object>(
  obj: T | null | undefined,
): obj is EmptyObjectOf<T> | null | undefined => {
  return !obj || Object.keys(obj).length === 0
}

/**
 * Checks if the user's email address input matches the valid email address format
 * and its length is not more than the valid email length
 *
 * @returns a boolean value
 */
export const isEmailValid = (email: string) => {
  const emailRegex = new RegExp(
    /^[^\s@]+@[^\s@\.]+\.[^\s@\.]+(\.[^\s@\.]+){0,93}$/,
  )
  const validEmailLength = 254
  const trimmedEmail = email?.trim()
  return (
    emailRegex.test(trimmedEmail) && trimmedEmail.length <= validEmailLength
  )
}

/**
 * Checks if the user's password input has at least 8 characters, 1 uppercase letter, 1 lowercase letter,
 * 1 symbol (including spaces and underscores) and both passwords match
 *
 * @returns an object of objects
 */
export const checkPasswordRequirements = (
  password: string,
  confirmPassword: string,
): Checks => {
  return {
    length: {
      text: "8 characters",
      fulfilled: password?.length >= 8 || confirmPassword?.length >= 8,
    },
    uppercase: {
      text: "1 upper case letter",
      fulfilled: /[A-Z]/.test(password) || /[A-Z]/.test(confirmPassword),
    },
    lowercase: {
      text: "1 lower case letter",
      fulfilled: /[a-z]/.test(password) || /[a-z]/.test(confirmPassword),
    },
    symbol: {
      text: "1 symbol (eg. !#$&)",
      fulfilled: /[\W_]/.test(password) || /[\W_]/.test(confirmPassword),
    },
    match: {
      text: "Confirm password match",
      fulfilled:
        password === "" || confirmPassword === ""
          ? false
          : password === confirmPassword,
    },
  }
}

/**
 * Check if the input is a string and it's not empty
 *
 * @param data any
 * @returns true if string is not empty and false otherwise
 */
export function isText(data: any): data is string {
  return typeof data === "string" && data.trim().length > 0
}

/**
 * Extracts the hostname from a given URL string
 *
 * This function removes the protocol (e.g., "http://", "https://") from the URL
 * and then extracts the hostname part by splitting the URL at the first "/".
 */
function getHostname(url: string) {
  return url.replace(/(^\w+:|^)\/\//, "").split("/")[0]
}

/**
 * Retruns the domain address of host url or undefined otherwise if not applicable
 */
export function extractDomain(url: string) {
  const hostname = getHostname(url)
  const parts = hostname?.split(".") ?? []

  return parts.length > 1 ? parts.slice(-2).join(".") : undefined
}

/**
 * Extracts the subdomain name from a URL or undefined otherwise if not applicable
 */
export function extractSubdomain(url: string) {
  const hostname = getHostname(url)
  const parts = hostname?.split(".") ?? []

  return parts.length > 2
    ? parts.slice(0, parts.length - 2).join(".")
    : undefined
}

export const sixDaysInSeconds = "518400"

/**
 * Retrieves an array of set-cookie headers
 */
export function makeSetCookieHeaders(tokens: Array<Token>) {
  const isProduction = process.env.NODE_ENV === "production"

  return tokens.map(({ kind, value, domain, maxAge, expires }) => {
    const name = kind === "jwt" ? getEnvironmentName() : kind
    if (kind === "user-data") {
      // this really shouldn't be the case ever,
      // I'll leave it in case of malformed tokens and unit tests
      if (makeUserDataCookie(value) === "") {
        return ""
      }

      const attributes = [
        makeUserDataCookie(value),
        ...(isProduction ? ["Secure"] : []),
        ...(isProduction && domain ? [`Domain=${domain}`] : []),
        ...(maxAge ? [`Max-Age=${maxAge}`] : []),
        ...(expires ? [`Expires=${expires}`] : []),
        "Path=/",
        "SameSite=Lax",
      ]

      return attributes.join(";")
    } else {
      const attributes = [
        `cultureamp.${name}-token=${value}`,
        ...(isProduction ? ["Secure"] : []),
        ...(isProduction && domain ? [`Domain=${domain}`] : []),
        ...(maxAge ? [`Max-Age=${maxAge}`] : []),
        ...(expires ? [`Expires=${expires}`] : []),
        "Path=/",
        "SameSite=Lax",
        "HttpOnly",
      ]

      return attributes.join(";")
    }
  })
}

/**
 * Retrieves an array of set-cookie headers
 */
export function makeUserDataCookie(token: string): string {
  try {
    const decoded = JWT.decodeJwt(token)
    const jwtToken = decoded as TokenValue
    let locale = jwtToken.locale ?? null
    if (locale) {
      locale = locale.replace(/_/g, "-")
    }
    const jwtObj = {
      locale: locale,
      employee_aggregate_id: jwtToken.effectiveUserId ?? "",
      account_aggregate_id: jwtToken.accountId ?? "",
    }
    const stringifiedValue = JSON.stringify(jwtObj)
    return `cultureamp.user-data=${encodeBase64(stringifiedValue)}`
  } catch (e) {
    return ""
  }
}

function encodeBase64(str: string): string {
  return Buffer.from(str).toString("base64")
}

/**
 * Retrieves the redirect url for successful logins
 */
export function getRedirectUrl(
  host: string,
  subdomain: string,
  req: NextApiRequest,
) {
  let path = "/session/new_sign_in"
  if (redirectCookieGetter(req)) {
    path = path + `?redirect=${encodeURIComponent(redirectCookieGetter(req))}`
  }

  if (host.startsWith("https://id.")) {
    return `${host.replace("id", subdomain)}${path}`
  }
  return `${host}${path}`
}

/**
 * Retrieves the Murmur's reset password URL for the given protocol and host
 *
 * @returns a string
 */
export function getMurmurResetPasswordUrl(protocol: string, host: string) {
  const resetPath = "session/password/new"

  if (host.startsWith("id.")) {
    return `${protocol}//${host.replace("id.", "identity.")}/${resetPath}`
  }

  return `${protocol}//${host}/${resetPath}`
}

/**
 * Retrieves the Murmur's SSO URL for the given protocol, host and subdomain
 *
 * @returns a string
 */
export function getMurmurSSOUrl(
  protocol: string,
  host: string,
  subdomain: string,
  redirect = "",
) {
  if (host.startsWith("id.")) {
    host = host.replace("id", subdomain)
  }

  if (redirect.length > 0) {
    redirect = `?redirect=${redirect}`
  }

  return `${protocol}//${host}/saml/${subdomain}${redirect}`
}

/**
 * Handles changes in the email input field's value and updates the workEmail state variable
 */
export const handleWorkEmailChange =
  (setWorkEmail: React.Dispatch<React.SetStateAction<string>>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = event.target.value
    setWorkEmail(emailInput)
  }

/**
 * Handles the onblur event on the email input field
 * to determine whether to display the email validation error message or not
 */
export const handleOnBlur =
  (
    workEmail: string,
    setShowInvalidEmailFormatError: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    setErrorCounter: React.Dispatch<React.SetStateAction<number>>,
  ) =>
  () => {
    const valid = isEmailValid(workEmail) || !isText(workEmail)
    setShowInvalidEmailFormatError(!valid)
    if (valid) {
      setErrorCounter(0)
    } else {
      setErrorCounter(prevCount => prevCount + 1)
    }
  }

/**
 * Parses the query parameters from the URL
 *
 * @returns an object of key-value pairs
 */
export function getQueryParams(search: string) {
  const queryParams: { [key: string]: string } = {}

  search.split("&").forEach(param => {
    const [key, value] = param.split("=")
    queryParams[key || ""] = decodeURIComponent(value || "")
  })

  return queryParams
}

/**
 * Remove the trailing slash from the URL
 *
 * @returns an object of key-value pairs
 */
export function removeTrailingSlash(url: string): string {
  if (url.endsWith("/")) {
    return url.substring(0, url.length - 1)
  }
  return url
}

/**
 * Check if the host is a US subdomain
 * @param host
 * @returns
 */
export function isUSSubdomain(host: string): boolean {
  const splitHost = host.split(".")
  const regions = ["eu", "au"]
  return !splitHost.some(part => regions.includes(part))
}

/**
 * Get the sign in URL
 * @param host
 * @returns
 */
export function getSignInUrl(
  protocol: string,
  host: string,
  isEURegion: boolean,
): string {
  const splitHost = host.split(".")
  let subdomain = splitHost[0] ?? ""
  if (splitHost.length == 3 && ["au", "eu"].includes(subdomain)) {
    subdomain = "id"
  }

  const pattern = `${protocol}//{subdomain}{env}.cultureamp.com`
  const url = pattern
    .replace("{subdomain}", subdomain)
    .replace("{env}", isEURegion ? "" : ".eu")
  return url
}

/**
 * Check if the user is authenticated
 *
 * @returns True if the user is authenticated, false otherwise
 */
export function checkUserAuthentication(req: NextApiRequest) {
  const authHeader = req.headers["x-ca-sgw-authorization"] as string
  if (authHeader) {
    const [bearerPlaceHolder, token] = authHeader.split(" ")

    return bearerPlaceHolder === "Bearer" && token
  }

  return false
}
